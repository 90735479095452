import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import ContractorPopUp from "../Project/ProjectOutputs/PopUps/ContractorPopUp";

const ContactTable = ({
    contacts,
    handleEditPersonInStore
}) => {
    const [openContactDetailsPopUp, setOpenContactDetailsPopUp] = useState(false);
    const [selectedContact, setSelectedContact] = useState(false);

    return (
        <>
            {openContactDetailsPopUp && (
                <ContractorPopUp
                    handleModal={() => {
                        setSelectedContact(null);
                        setOpenContactDetailsPopUp(false);
                    }}
                    modalState={openContactDetailsPopUp}
                    personId={selectedContact.id}
                    scheduleItemId={null}
                    reloadPersonList={() => console.log("No reload")}
                    reloadScheduleItemsList={() => console.log("No reload")}
                    type="ContactCenterTable"
                    providerType={selectedContact.personCategory}
                    handleEditPerson={handleEditPersonInStore}
                    selectedContact={selectedContact}
                    rowUpdated={() => {}}
                />
            )}
            <Table className="contact-table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Category</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...contacts].map(c => {
                        return (
                            <TableRow key={`contact-center-person-${c.id}-row`}
                                onClick={() => {
                                    setSelectedContact(c);
                                    setOpenContactDetailsPopUp(true);
                                }}
                            >
                                <TableCell>
                                    {`${c.firstName} ${c.lastName}`}
                                </TableCell>
                                <TableCell>{`${c.emailAddress}`}</TableCell>
                                <TableCell>{`${c.phoneNumber}`}</TableCell>
                                <TableCell>{`${c.personCategory}`}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </>
    )
}

export default ContactTable;