import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { filterNumbersOnlyInput, getCurrencies } from "../../../utils/GeneralUtils";

const initialErrorState = {
  supplier: "",
};

const PayableMediaForm = ({
  payable,
  handleUpdateLocalPayable,
  mediaSuppliers,
  companies,
}) => {
  const [errorForm, setErrorForm] = useState(initialErrorState);
  // Memoize the currencies to avoid recalculating on each render
  const currencies = useMemo(() => getCurrencies(), []);


  const GstRateCodeCheckbox = () => {
    return (
      <div style={{ justifyContent: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              name="gst-checkbox"
              checked={payable.gstRateCode === "INPUT2"}
              onChange={(e) =>
                handleUpdateLocalPayable("gstRateCode", "INPUT2")
              }
            />
          }
          label="GST Inc"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="no-gst-checkbox"
              checked={payable.gstRateCode === "NONE"}
              onChange={(e) => handleUpdateLocalPayable("gstRateCode", "NONE")}
            />
          }
          label="No GST"
        />
      </div>
    );
  };

  return (
    <div className="payable-media-form-root">
      <div className="payable-media-form-columns-container">
        <Autocomplete
          options={companies}
          value={companies.find((c) => c.id === payable?.companyId)}
          getOptionLabel={(option) => option.name}
          onChange={(e, newValue) => {
            handleUpdateLocalPayable("companyId", newValue);
          }}
          selectOnFocus
          renderOption={(option) => {
            return <li key={option.id}>{option.name}</li>;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Client"
              variant="filled"
              size="small"
              fullWidth={true}
            />
          )}
        />
        <Autocomplete
          value={mediaSuppliers.find((ms) => ms.id === payable.mediaSupplierId)}
          options={mediaSuppliers}
          onChange={(event, option) => {
            handleUpdateLocalPayable("mediaSupplierId", option);
          }}
          getOptionLabel={(option) => option.supplierName}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Supplier"}
              fullWidth={true}
              variant="filled"
              error={errorForm.supplier ? true : false}
              helperText={errorForm.supplier ? errorForm.supplier : ""}
            />
          )}
          disableClearable
          fullWidth
          size="small"
        />
        <TextField
          variant="filled"
          label="Reference"
          fullWidth
          value={payable.reference}
          onChange={(e) =>
            handleUpdateLocalPayable("reference", e.target.value)
          }
          size="small"
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            label={"Date"}
            value={payable.date}
            format="dd/MM/yyyy"
            onChange={(date) =>
              handleUpdateLocalPayable(
                "date",
                moment(date).format("YYYY-MM-DD")
              )
            }
            fullWidth
            inputVariant={"filled"}
            size="small"
          />
        </MuiPickersUtilsProvider>
        <div className="reconciliation-align-button-container">
          <TextField
            variant="filled"
            label={payable.gstRateCode !== "NONE" ? "NZD Gst Inc" : "Total"}
            fullWidth
            value={payable.total}
            onChange={(e) =>
              handleUpdateLocalPayable(
                "total",
                filterNumbersOnlyInput(e.target.value)
              )
            }
            size="small"
          />
          {payable.gstRateCode !== "NONE" && (
            <TextField
              variant="filled"
              label="Subtotal"
              disabled
              fullWidth
              value={payable.subtotal}
              size="small"
            />
          )}
        </div>

        {GstRateCodeCheckbox()}
      </div>
      <div className="reconciliation-align-note-container">
        <TextField
          variant="filled"
          label="Note"
          fullWidth
          value={payable.note}
          onChange={(e) => handleUpdateLocalPayable("note", e.target.value)}
          size="small"
        />
        <Autocomplete
          options={currencies}
          value={currencies.find(c => c.value === payable.currency) || { value: "NZD" }}
          getOptionLabel={(option) => option?.value}
          onChange={(e, newValue) => {
            handleUpdateLocalPayable("currency", newValue?.value || "NZD");
          }}
          selectOnFocus
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              label="Currency"
              variant="filled"
              size="small"
              fullWidth={true}
            />
          )}
        />
        <Autocomplete
          options={["Invoice", "Credit"]}
          value={payable.payableType}
          getOptionLabel={(option) => option}
          onChange={(e, newValue) => {
            handleUpdateLocalPayable("payableType", newValue);
          }}
          selectOnFocus
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type"
              variant="filled"
              size="small"
              fullWidth={true}
            />
          )}
        />
      </div>
    </div>
  );
};

export default PayableMediaForm;
